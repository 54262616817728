import {Observable} from 'rxjs';
import {MembershipSubscriptionInfo} from './domain/membership-subscription-info';
import {BundleSubscriptionInfo} from './domain/bundle-subscription-info';
import {BundlePurchaseInfo} from './domain/bundle-purchase-info';

export abstract class PurchaseManagementApiService {
  abstract getCommunityMemberships(): Observable<MembershipSubscriptionInfo[]>;
  abstract getContentSubscriptions(): Observable<BundleSubscriptionInfo[]>;
  abstract getContentOneTimePurchases(): Observable<BundlePurchaseInfo[]>;

  abstract cancelCommunityMembership(trackingId: string): Observable<void>;
  abstract cancelContentSubscription(trackingId: string): Observable<void>;
  abstract reactivateCommunityMembership(trackingId: string, expiry?: number): Observable<void>;
  abstract reactivateContentSubscription(trackingId: string, expiry?: number): Observable<void>;
}
